/* eslint-disable no-underscore-dangle */
/* eslint-disable no-restricted-syntax */
const defaultPage = 'Dashboard';

const navigationMap = new Map([
  [defaultPage, {
    name: 'DashBorad', to: '/dashboard', icon: 'cil-speedometer', _tag: 'CSidebarNavItem',
  }],
  ['ApiMls', {
    _tag: 'CSidebarNavDropdown',
    name: 'ApiMls',
    route: '/mlsmapping',
    to: '/mlsmapping',
    icon: 'cil-drop',
    _children: [
      { name: 'Mls Mapping', to: '/mlsmapping', _tag: 'CSidebarNavItem' },
    ],
  }],
  ['DataFixer', {
    name: 'Data Fixer', to: '/datafixer', icon: 'cil-puzzle', _tag: 'CSidebarNavItem',
  }],
  ['DataBrowser', {
    name: 'Data Browser', to: '/databrowser', icon: 'cil-bell', _tag: 'CSidebarNavItem',
  }],
  ['Notifications', {
    name: 'Notifications', to: '/notificationsview', icon: 'cil-pencil', _tag: 'CSidebarNavItem',
  }],
  ['MlsWireTools', {
    _tag: 'CSidebarNavDropdown',
    name: 'Mls Wire Tools',
    route: '/mls-wire',
    to: '/mls-wire/mlsconfiguration',
    icon: 'cil-cursor',
    _children: [
      { name: 'Configuration', to: '/mls-wire/mlsconfiguration', _tag: 'CSidebarNavItem' },
      { name: 'Metadata', to: '/mls-wire/mlsmetadata', _tag: 'CSidebarNavItem' },
      { name: 'Geo Job', to: '/mls-wire/geojob', _tag: 'CSidebarNavItem' },
      { name: 'Class Jobs', to: '/mls-wire/classjobs', _tag: 'CSidebarNavItem' },
      { name: 'Photo Job', to: '/mls-wire/photojob', _tag: 'CSidebarNavItem' },
    ],
  }],
  ['Monitor', {
    _tag: 'CSidebarNavDropdown',
    name: 'Monitor',
    route: '/classjoberror',
    to: '/classjoberror',
    icon: 'cil-star',
    _children: [
      { name: 'Class Job Error', to: '/classjoberror', _tag: 'CSidebarNavItem' },
      { name: 'Photo Job Error', to: '/photojoberror', _tag: 'CSidebarNavItem' },
    ],
  }],
]);

const defaultAllowdPages = [
  '',
  '/',
  '/login',
  '/logout',
];

const generateSidebar = (frontendPages) => {
  const pages = [];

  for (const item of frontendPages) {
    const page = navigationMap.get(item.name);
    if (page) {
      pages.push(page);
    }
  }
  return pages;
};

const getDefaultPage = (frontendPages) => {
  const page = frontendPages.find((item) => item.options?.is_primary === 1);

  const selectedPage = page?.name || defaultPage;
  const navigation = navigationMap.get(selectedPage) || navigationMap.get(defaultPage);

  return navigation.to;
};
const isPathnameAllowed = (pathname, frontendPages) => {
  if (defaultAllowdPages.indexOf(pathname) !== -1) {
    return true;
  }

  let pageName = null;
  for (const [key, item] of navigationMap) {
    if ('_children' in item) {
      for (const children of item._children) {
        if (pathname === children.to) {
          pageName = key;
          break;
        }
      }
    } else if (pathname === item.to) {
      pageName = key;
      break;
    }
  }

  return pageName && !!frontendPages.find((item) => item.name === pageName);
};

export {
  generateSidebar,
  getDefaultPage,
  isPathnameAllowed,
};
