import axios from 'axios';

export const TOKEN = 'TOKEN';
export const REFRESH_TOKEN = 'REFRESH_TOKEN';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
  responseType: 'json',
});

api.interceptors.request.use((config) => {
  if (localStorage.getItem(TOKEN)) {
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = `Bearer ${localStorage.getItem(TOKEN)}`;
  }

  return config;
}, (error) => Promise.reject(error));

api.interceptors.response.use((response) => response, (error) => {
  if (error?.response?.status === 401
      && window.location.pathname !== '/logout') {
    window.location.href = `${window.location.origin}/logout`;
  }
  return Promise.reject(error);
});

export default api;
