import Echo from 'laravel-echo';
import SocketIo from 'socket.io-client';

window.io = SocketIo;

const websocketInit = () => {
  if (process.env.REACT_APP_WEBSOCKET_ENABLED === 'true') {
    try {
      window.Echo = new Echo({
        broadcaster: 'socket.io',
        host: `${window.location.hostname}:6001`,
      });
    } catch (error) {
      //
    }
  }
};

export default websocketInit;
